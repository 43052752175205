import React from 'react'
import './Forums.css'
import Blogcard from './Blogcard'
import Currblog from './Currblog'

function Forums() {
    return (
        <>
        <Currblog/>
      <Blogcard/>
    </>
    )
}

export default Forums
