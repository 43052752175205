import React from 'react';
import './ContactUs.css';
import Part1 from '../ContactUs/components/Part1';
import Part2 from '../ContactUs/components/Part2';


function ContactUs() {
  return (
    <>
      <Part1 />
      <Part2 />
    </>
  )
}

export default ContactUs
