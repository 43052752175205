import React from "react";
import './AboutUs.css';
import Image from "./Image";
import Info from "./Info";
import OurTeam from "./OurTeam";
import StillHere from "./StillHere";






function AboutUs() {
  return (
    <>
      
      <Image/>
      <Info/>
       <OurTeam/>
      <StillHere/>
      
    </>
  );
}

export default AboutUs;
